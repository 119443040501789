<template>
  <v-container class="px-6 d-block" fluid>
    <div class="d-flex align-center mb-6">
      <v-icon size="15" color="#4ab762">mdi-home</v-icon>
      <v-breadcrumbs :items="breadcrumbs" class="pa-0 ml-2">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <v-row>
      <v-col cols="12">
        <v-card
          class="rounded-lg"
          style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px"
        >
          <v-card-title
            class="subtitle-1 grey--text text--darken-2 font-weight-bold"
            >Data Promo Diskon</v-card-title
          >
          <v-card-text class="px-0 py-0">
            <div class="px-4 d-flex justify-space-between">
              <v-btn-toggle dense class="mb-2">
                <v-btn
                  color="#2ecc71"
                  class="text-none"
                  text
                  small
                  :disabled="loading"
                  @click="dialog.promo = true"
                >
                  <v-icon size="16" color="#2ecc71"> mdi-plus-box </v-icon>
                  <span class="hidden-sm-and-down ml-1">Tambah</span>
                </v-btn>

                <v-btn
                  color="#7f8c8d"
                  :disabled="loading || selected.promo.length <= 0"
                  class="text-none"
                  text
                  small
                  @click="
                    setForm();
                    dialog.promo = true;
                  "
                >
                  <v-icon size="16" color="#7f8c8d"> mdi-pencil-box </v-icon>
                  <span class="hidden-sm-and-down ml-1">Ubah</span>
                </v-btn>

                <v-btn
                  color="#3498db"
                  class="text-none"
                  :disabled="loading || selected.promo.length <= 0"
                  text
                  small
                  @click="dialog.detail = true"
                >
                  <v-icon size="16" color="#3498db"> mdi-information </v-icon>
                  <span class="hidden-sm-and-down ml-1">Detail</span>
                </v-btn>

                <v-btn
                  color="#e74c3c"
                  class="text-none"
                  :disabled="loading || selected.promo.length <= 0"
                  text
                  small
                  @click="
                    setForm();
                    dialog.confirmDelete = true;
                  "
                >
                  <v-icon size="16" color="#e74c3c">
                    mdi-delete-forever
                  </v-icon>
                  <span class="hidden-sm-and-down ml-1">Hapus</span>
                </v-btn>
              </v-btn-toggle>
            </div>
            <v-data-table
              v-model="selected.promo"
              :headers="headers.promo"
              :items="dataGrid.promo"
              :loading="loading"
              :options.sync="options.promo"
              :server-items-length="totalData.promo"
              loading-text="Sedang memuat"
              :no-data-text="'Data belum ada'"
              no-results-text="Data belum ada"
              sort-by-text="Urutkan berdasarkan"
              :items-per-page="10"
              height="calc(100vh - 340px)"
              fixed-header
              :footer-props="footerProps"
              item-key="id"
              flat
              single-select
              show-select
              class="elevation-0 custom-grid rounded-lg"
            >
              <template v-slot:[`item.active_bool`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(46 204 113);
                    background-color: rgb(46 204 113 / 10%);
                  "
                  v-if="item.active_bool"
                >
                  Aktif
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(231, 76, 60);
                    background-color: rgb(231 76 60 / 10%);
                  "
                  v-else
                >
                  Tidak Aktif
                </v-chip>
              </template>

              <template v-slot:[`item.item_type`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(241 196 15);
                    background-color: rgb(241 196 15 / 10%);
                  "
                  v-if="item.item_type == 'category'"
                >
                   {{ item.item_array.length > 0 ? 'Kategori tertentu' : 'Semua Kategori'}}
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(46 204 113);
                    background-color: rgb(46 204 113 / 10%);
                  "
                  v-if="item.item_type == 'publisher'"
                >
                  {{ item.item_array.length > 0 ? 'Publisher tertentu' : 'Semua Publisher'}}
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 152 219);
                    background-color: rgb(52 152 219 / 10%);
                  "
                  v-if="item.item_type == 'organization'"
                >
                  {{ item.item_array.length > 0 ? 'Organisasi tertentu' : 'Semua Organisasi'}}
                </v-chip>
              </template>

              <template v-slot:[`item.platform`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(241 196 15);
                    background-color: rgb(241 196 15 / 10%);
                  "
                  v-if="item.platform == 'mobile'"
                >
                  Aplikasi Mobile
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 152 219);
                    background-color: rgb(52 152 219 / 10%);
                  "
                  v-if="item.platform == 'web'"
                >
                  Aplikasi Web
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(149 165 166);
                    background-color: rgb(149 165 166 / 10%);
                  "
                  v-if="item.platform == 'semua'"
                >
                  Semua Aplikasi
                </v-chip>
              </template>

              <template v-slot:[`item.percent`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 152 219);
                    background-color: rgb(52 152 219 / 10%);
                  "
                >
                  <v-icon small left color="rgb(52 152 219)">mdi-sale</v-icon
                  >{{ item.percent + "%" }}
                </v-chip>
              </template>

              <template v-slot:[`item.periode_array`]="{ item }">
                {{ item.periode_array.format | emptyData }}
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 152 219);
                    background-color: rgb(52 152 219 / 10%);
                  "
                  v-if="item.coming_bool"
                >
                  Akan datang
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(46 204 113);
                    background-color: rgb(46 204 113 / 10%);
                  "
                  v-if="item.started_bool"
                >
                  Masih berlangsung
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(1231 76 60);
                    background-color: rgb(1231 76 60 / 10%);
                  "
                  v-if="item.ended_bool"
                >
                  Telah berakhir
                </v-chip>
              </template>
              <template v-slot:[`item.apply_periode_array`]="{ item }">
                {{ item.apply_periode_array.format | emptyData }}
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 152 219);
                    background-color: rgb(52 152 219 / 10%);
                  "
                  v-if="item.apply_coming_bool"
                >
                  Akan datang
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(46 204 113);
                    background-color: rgb(46 204 113 / 10%);
                  "
                  v-if="item.apply_started_bool"
                >
                  Masih berlangsung
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(1231 76 60);
                    background-color: rgb(1231 76 60 / 10%);
                  "
                  v-if="item.apply_ended_bool"
                >
                  Telah berakhir
                </v-chip>
              </template>
              <template v-slot:[`item.input_datetime`]="{ item }">
                {{ item.input_datetime | datetime | emptyData }}
              </template>
              <template v-slot:[`item.update_datetime`]="{ item }">
                {{ item.update_datetime | datetime | emptyData }}
              </template>

              <template v-slot:[`footer.page-text`]="props">
                Menampilkan {{ props.pageStart }} - {{ props.pageStop }} data
                dari total {{ props.itemsLength }} data
                <v-btn
                  text
                  class="ml-4 text-none"
                  small
                  @click="
                    fetchData();
                    selected.promo = [];
                  "
                  ><v-icon left>mdi-sync</v-icon> Perbarui</v-btn
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- FORM -->
    <v-dialog
      v-model="dialog.promo"
      v-if="dialog.promo"
      persistent
      no-click-animation
      max-width="450"
      transition="slide-x-reverse-transition"
      content-class="my-custom-dialog"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          {{ form.id > 0 ? "Ubah" : "Tambah" }} Promo Diskon
          <div class="caption grey--text text--darken-1">
            Lengkapi form dibawah ini
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: calc(100vh - 118px)" class="py-4 px-0">
          <v-form
            ref="form-promo"
            v-model="valid"
            lazy-validation
            @keyup.native.enter="doSave"
          >
            <div class="px-4">
              <ImageUploadLandscape v-model="form.image_landscape_url" class="mb-4" />
              
              <ImageUpload v-model="form.image_square_url" class="mb-4" />

              <div class="subtitle-2">
                Nama Promo
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="text"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.name"
                :error-messages="validation.name"
                @keyup="validation.name = ''"
                :rules="[
                  (v) => !!v || `Nama promo tidak boleh kosong`,
                  (v) =>
                    (v && v.length <= 100) ||
                    'Nama promo maksimal 100 karakter',
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2 mt-4">
                Syarat Promo
                <small style="color: #e74c3c"><i> *wajib diisi</i></small>
              </div>
              <!-- <v-autocomplete
                dense
                :items="itemTypeData"
                color="#4ab762"
                v-model="form.item_type"
                :disabled="loading"
                solo
                item-text="label"
                item-value="value"
                class="rounded-lg mb-3"
                :error-messages="validation.item_type"
                @keyup="validation.item_type = ''"
                @keyup.delete="deleteType"
                placeholder="Pilih disini"
              >
              </v-autocomplete> -->

              <v-list-item
                class="py-0 rounded-lg"
                style="background-color: #f9f9f9; border: 1px solid #ebebeb"
                @click="dialog.itemType = true"
              >
                <v-list-item-avatar
                  class="py-0 rounded-lg"
                  tile
                  color="rgba(74, 183, 98, 0.7)"
                >
                  <v-icon color="#fff">mdi-tag-multiple</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 grey--text text--darken-1"
                  >
                    {{ computedSyarat.title }}</v-list-item-title
                  >
                  <v-list-item-subtitle
                    class="caption grey--text text--darken-1"
                    >{{ computedSyarat.subtitle }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="#7f8c8d">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

              <div class="subtitle-2 mt-4">
                Pilih Platform
                <small style="color: #e74c3c"><i> *wajib diisi</i></small>
              </div>
              <v-autocomplete
                dense
                :items="platformData"
                color="#4ab762"
                v-model="form.platform"
                :disabled="loading"
                solo
                item-text="label"
                item-value="value"
                class="rounded-lg mb-3"
                :error-messages="validation.platform"
                @keyup="validation.platform = ''"
                @keyup.delete="deleteType"
                placeholder="Pilih disini"
              >
              </v-autocomplete>

              <div class="subtitle-2">
                Diskon <small style="color: #f39c12"><i>(dalam %)</i></small>
                <small style="color: #e74c3c"
                  ><i> *wajib diisi minimal 0</i></small
                >
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="number"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.percent"
                :error-messages="validation.percent"
                @keyup="validation.percent = ''"
                :rules="[
                  (v) =>
                    (v != null && v != '' && v >= 0 && v <= 100) ||
                    'Diskon harus antara 0 sampai 100',
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </div>

            <div
              style="background: rgba(0, 0, 0, 0.1)"
              class="mb-3 mt-6 px-4 py-2 subtitle-2"
            >
              PERIODE PROMO
            </div>

            <div class="px-4">
              <div class="subtitle-2">
                Pilih Tanggal dan Jam Mulai Promo
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <div>
                <v-dialog
                  ref="dateStart"
                  v-model="dialog.dateStart"
                  :return-value.sync="form.start_date"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      solo
                      autocomplete="off"
                      color="#4ab762"
                      placeholder="Pilih disini"
                      class="rounded-lg mb-3"
                      :disabled="loading"
                      v-model="computedStartDate"
                      :error-messages="validation.start_date"
                      @change="validation.start_date = ''"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="max-width: 60%; display: inline-block"
                    >
                      <template v-slot:message="{ message }">
                        <v-tooltip top max-width="250" color="#e74c3c">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              size="22"
                              class="mr-2 ml-n2"
                              color="#e74c3c"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </template>
                          <span>{{ message }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    v-model="form.start_date"
                    class="rounded-lg"
                    color="#4ab762"
                  >
                    <div
                      class="d-flex justify-space-between"
                      style="width: 100%"
                    >
                      <v-btn
                        rounded
                        depressed
                        class="
                          text-none
                          white--text
                          text--lighten-3
                          flex-grow-1
                        "
                        color="#4ab762"
                        @click="$refs.dateStart.save(form.start_date)"
                      >
                        <v-icon left>mdi-check-circle-outline</v-icon>
                        Terapkan
                      </v-btn>
                      <v-btn
                        rounded
                        depressed
                        outlined
                        class="text-none ml-2"
                        color="#e74c3c"
                        width="80"
                        @click="dialog.dateStart = false"
                      >
                        Batal
                      </v-btn>
                    </div>
                  </v-date-picker>
                </v-dialog>
                <v-dialog
                  ref="timeStart"
                  v-model="dialog.timeStart"
                  :return-value.sync="form.start_time"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      solo
                      autocomplete="off"
                      color="#4ab762"
                      placeholder="Pilih disini"
                      class="rounded-lg mb-3 ml-4"
                      :disabled="loading"
                      v-model="form.start_time"
                      :error-messages="validation.start_time"
                      @change="validation.start_time = ''"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="max-width: 35%; display: inline-block"
                    >
                      <template v-slot:message="{ message }">
                        <v-tooltip top max-width="250" color="#e74c3c">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              size="22"
                              class="mr-2 ml-n2"
                              color="#e74c3c"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </template>
                          <span>{{ message }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </template>
                  <v-time-picker
                    no-title
                    format="24hr"
                    v-if="dialog.timeStart"
                    v-model="form.start_time"
                    class="rounded-lg"
                    color="#4ab762"
                  >
                    <div
                      class="d-flex justify-space-between"
                      style="width: 100%"
                    >
                      <v-btn
                        rounded
                        depressed
                        class="
                          text-none
                          white--text
                          text--lighten-3
                          flex-grow-1
                        "
                        color="#4ab762"
                        @click="$refs.timeStart.save(form.start_time)"
                      >
                        <v-icon left>mdi-check-circle-outline</v-icon>
                        Terapkan
                      </v-btn>
                      <v-btn
                        rounded
                        depressed
                        outlined
                        class="text-none ml-2"
                        color="#e74c3c"
                        width="80"
                        @click="dialog.timeStart = false"
                      >
                        Batal
                      </v-btn>
                    </div>
                  </v-time-picker>
                </v-dialog>
              </div>

              <div class="subtitle-2">
                Pilih Tanggal dan Jam Berakhir Promo
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <div>
                <v-dialog
                  ref="dateEnd"
                  v-model="dialog.dateEnd"
                  :return-value.sync="form.end_date"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      solo
                      autocomplete="off"
                      color="#4ab762"
                      placeholder="Pilih disini"
                      class="rounded-lg mb-3"
                      :disabled="loading"
                      v-model="computedEndDate"
                      :error-messages="validation.end_date"
                      @change="validation.end_date = ''"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="max-width: 60%; display: inline-block"
                    >
                      <template v-slot:message="{ message }">
                        <v-tooltip top max-width="250" color="#e74c3c">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              size="22"
                              class="mr-2 ml-n2"
                              color="#e74c3c"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </template>
                          <span>{{ message }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    v-model="form.end_date"
                    :min="form.start_date"
                    class="rounded-lg"
                    color="#4ab762"
                  >
                    <div
                      class="d-flex justify-space-between"
                      style="width: 100%"
                    >
                      <v-btn
                        rounded
                        depressed
                        class="
                          text-none
                          white--text
                          text--lighten-3
                          flex-grow-1
                        "
                        color="#4ab762"
                        @click="$refs.dateEnd.save(form.end_date)"
                      >
                        <v-icon left>mdi-check-circle-outline</v-icon>
                        Terapkan
                      </v-btn>
                      <v-btn
                        rounded
                        depressed
                        outlined
                        class="text-none ml-2"
                        color="#e74c3c"
                        width="80"
                        @click="dialog.dateEnd = false"
                      >
                        Batal
                      </v-btn>
                    </div>
                  </v-date-picker>
                </v-dialog>
                <v-dialog
                  ref="timeEnd"
                  v-model="dialog.timeEnd"
                  :return-value.sync="form.end_time"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      solo
                      autocomplete="off"
                      color="#4ab762"
                      placeholder="Pilih disini"
                      class="rounded-lg mb-3 ml-4"
                      :disabled="loading"
                      v-model="form.end_time"
                      :error-messages="validation.end_time"
                      @change="validation.end_time = ''"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="max-width: 35%; display: inline-block"
                    >
                      <template v-slot:message="{ message }">
                        <v-tooltip top max-width="250" color="#e74c3c">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              size="22"
                              class="mr-2 ml-n2"
                              color="#e74c3c"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </template>
                          <span>{{ message }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </template>
                  <v-time-picker
                    no-title
                    format="24hr"
                    v-if="dialog.timeEnd"
                    v-model="form.end_time"
                    class="rounded-lg"
                    color="#4ab762"
                  >
                    <div
                      class="d-flex justify-space-between"
                      style="width: 100%"
                    >
                      <v-btn
                        rounded
                        depressed
                        class="
                          text-none
                          white--text
                          text--lighten-3
                          flex-grow-1
                        "
                        color="#4ab762"
                        @click="$refs.timeEnd.save(form.end_time)"
                      >
                        <v-icon left>mdi-check-circle-outline</v-icon>
                        Terapkan
                      </v-btn>
                      <v-btn
                        rounded
                        depressed
                        outlined
                        class="text-none ml-2"
                        color="#e74c3c"
                        width="80"
                        @click="dialog.timeEnd = false"
                      >
                        Batal
                      </v-btn>
                    </div>
                  </v-time-picker>
                </v-dialog>
              </div>
            </div>

            <div
              style="background: rgba(0, 0, 0, 0.1)"
              class="mb-3 mt-4 px-4 py-2 subtitle-2"
            >
              PERIODE PENGAJUAN
            </div>

            <div class="px-4">
              <div class="subtitle-2">
                Pilih Tanggal dan Jam Mulai Pengajuan
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <div>
                <v-dialog
                  ref="applyDateStart"
                  v-model="dialog.applyDateStart"
                  :return-value.sync="form.start_apply_date"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      solo
                      autocomplete="off"
                      color="#4ab762"
                      placeholder="Pilih disini"
                      class="rounded-lg mb-3"
                      :disabled="loading"
                      v-model="computedStartApplyDate"
                      :error-messages="validation.start_apply_date"
                      @change="validation.start_apply_date = ''"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="max-width: 60%; display: inline-block"
                    >
                      <template v-slot:message="{ message }">
                        <v-tooltip top max-width="250" color="#e74c3c">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              size="22"
                              class="mr-2 ml-n2"
                              color="#e74c3c"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </template>
                          <span>{{ message }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    v-model="form.start_apply_date"
                    class="rounded-lg"
                    color="#4ab762"
                  >
                    <div
                      class="d-flex justify-space-between"
                      style="width: 100%"
                    >
                      <v-btn
                        rounded
                        depressed
                        class="
                          text-none
                          white--text
                          text--lighten-3
                          flex-grow-1
                        "
                        color="#4ab762"
                        @click="
                          $refs.applyDateStart.save(form.start_apply_date)
                        "
                      >
                        <v-icon left>mdi-check-circle-outline</v-icon>
                        Terapkan
                      </v-btn>
                      <v-btn
                        rounded
                        depressed
                        outlined
                        class="text-none ml-2"
                        color="#e74c3c"
                        width="80"
                        @click="dialog.applyDateStart = false"
                      >
                        Batal
                      </v-btn>
                    </div>
                  </v-date-picker>
                </v-dialog>
                <v-dialog
                  ref="applyTimeStart"
                  v-model="dialog.applyTimeStart"
                  :return-value.sync="form.start_apply_time"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      solo
                      autocomplete="off"
                      color="#4ab762"
                      placeholder="Pilih disini"
                      class="rounded-lg mb-3 ml-4"
                      :disabled="loading"
                      v-model="form.start_apply_time"
                      :error-messages="validation.start_apply_time"
                      @change="validation.start_apply_time = ''"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="max-width: 35%; display: inline-block"
                    >
                      <template v-slot:message="{ message }">
                        <v-tooltip top max-width="250" color="#e74c3c">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              size="22"
                              class="mr-2 ml-n2"
                              color="#e74c3c"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </template>
                          <span>{{ message }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </template>
                  <v-time-picker
                    no-title
                    format="24hr"
                    v-if="dialog.applyTimeStart"
                    v-model="form.start_apply_time"
                    class="rounded-lg"
                    color="#4ab762"
                  >
                    <div
                      class="d-flex justify-space-between"
                      style="width: 100%"
                    >
                      <v-btn
                        rounded
                        depressed
                        class="
                          text-none
                          white--text
                          text--lighten-3
                          flex-grow-1
                        "
                        color="#4ab762"
                        @click="
                          $refs.applyTimeStart.save(form.start_apply_time)
                        "
                      >
                        <v-icon left>mdi-check-circle-outline</v-icon>
                        Terapkan
                      </v-btn>
                      <v-btn
                        rounded
                        depressed
                        outlined
                        class="text-none ml-2"
                        color="#e74c3c"
                        width="80"
                        @click="dialog.applyTimeStart = false"
                      >
                        Batal
                      </v-btn>
                    </div>
                  </v-time-picker>
                </v-dialog>
              </div>

              <div class="subtitle-2">
                Pilih Tanggal dan Jam Berakhir Pengajuan
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <div>
                <v-dialog
                  ref="applyDateEnd"
                  v-model="dialog.applyDateEnd"
                  :return-value.sync="form.end_apply_date"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      solo
                      autocomplete="off"
                      color="#4ab762"
                      placeholder="Pilih disini"
                      class="rounded-lg mb-3"
                      :disabled="loading"
                      v-model="computedEndApplyDate"
                      :error-messages="validation.end_apply_date"
                      @change="validation.end_apply_date = ''"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="max-width: 60%; display: inline-block"
                    >
                      <template v-slot:message="{ message }">
                        <v-tooltip top max-width="250" color="#e74c3c">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              size="22"
                              class="mr-2 ml-n2"
                              color="#e74c3c"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </template>
                          <span>{{ message }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    v-model="form.end_apply_date"
                    :min="form.end_apply_date"
                    class="rounded-lg"
                    color="#4ab762"
                  >
                    <div
                      class="d-flex justify-space-between"
                      style="width: 100%"
                    >
                      <v-btn
                        rounded
                        depressed
                        class="
                          text-none
                          white--text
                          text--lighten-3
                          flex-grow-1
                        "
                        color="#4ab762"
                        @click="$refs.applyDateEnd.save(form.end_apply_date)"
                      >
                        <v-icon left>mdi-check-circle-outline</v-icon>
                        Terapkan
                      </v-btn>
                      <v-btn
                        rounded
                        depressed
                        outlined
                        class="text-none ml-2"
                        color="#e74c3c"
                        width="80"
                        @click="dialog.applyDateEnd = false"
                      >
                        Batal
                      </v-btn>
                    </div>
                  </v-date-picker>
                </v-dialog>
                <v-dialog
                  ref="applyTimeEnd"
                  v-model="dialog.applyTimeEnd"
                  :return-value.sync="form.end_apply_time"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      solo
                      autocomplete="off"
                      color="#4ab762"
                      placeholder="Pilih disini"
                      class="rounded-lg mb-3 ml-4"
                      :disabled="loading"
                      v-model="form.end_apply_time"
                      :error-messages="validation.end_apply_time"
                      @change="validation.end_apply_time = ''"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="max-width: 35%; display: inline-block"
                    >
                      <template v-slot:message="{ message }">
                        <v-tooltip top max-width="250" color="#e74c3c">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              size="22"
                              class="mr-2 ml-n2"
                              color="#e74c3c"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </template>
                          <span>{{ message }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </template>
                  <v-time-picker
                    no-title
                    format="24hr"
                    v-if="dialog.applyTimeEnd"
                    v-model="form.end_apply_time"
                    class="rounded-lg"
                    color="#4ab762"
                  >
                    <div
                      class="d-flex justify-space-between"
                      style="width: 100%"
                    >
                      <v-btn
                        rounded
                        depressed
                        class="
                          text-none
                          white--text
                          text--lighten-3
                          flex-grow-1
                        "
                        color="#4ab762"
                        @click="$refs.applyTimeEnd.save(form.end_apply_time)"
                      >
                        <v-icon left>mdi-check-circle-outline</v-icon>
                        Terapkan
                      </v-btn>
                      <v-btn
                        rounded
                        depressed
                        outlined
                        class="text-none ml-2"
                        color="#e74c3c"
                        width="80"
                        @click="dialog.applyTimeEnd = false"
                      >
                        Batal
                      </v-btn>
                    </div>
                  </v-time-picker>
                </v-dialog>
              </div>
            </div>

            <div
              style="background: rgba(0, 0, 0, 0.1)"
              class="mb-3 mt-4 px-4 py-2 subtitle-2"
            >
              INFORMASI LAINNYA
            </div>

            <div class="px-4">
              <div class="subtitle-2 mt-4">
                Status
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <v-radio-group
                v-model="form.active_bool"
                row
                dense
                hide-details
                class="mt-0 mb-3"
              >
                <v-radio label="Aktif" :value="true" color="#4ab762"></v-radio>
                <v-radio
                  label="Tidak Aktif"
                  :value="false"
                  color="#4ab762"
                ></v-radio>
              </v-radio-group>

              <div class="subtitle-2 mt-4">
                Deskripsi
                <small style="color: #2ecc71"><i>opsional</i></small>
              </div>
              <v-textarea
                dense
                solo
                rows="5"
                no-resize
                autocomplete="off"
                color="#4ab762"
                type="text"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                maxlength="1000"
                v-model="form.description"
                :error-messages="validation.description"
                @keyup="validation.description = ''"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-textarea>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            rounded
            depressed
            class="text-none white--text text--lighten-3 flex-grow-1"
            color="#4ab762"
            @click="doSave"
          >
            <v-icon left>mdi-check-circle-outline</v-icon
            >{{ form.id > 0 ? "Simpan" : "Tambahkan" }} Promo
          </v-btn>
          <v-btn
            rounded
            depressed
            outlined
            class="text-none"
            color="#e74c3c"
            width="130"
            @click="
              dialog.promo = false;
              formReset();
              $refs['form-promo'].resetValidation();
              validationReset();
            "
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.itemType"
      v-if="dialog.itemType"
      persistent
      no-click-animation
      max-width="450"
      transition="slide-x-reverse-transition"
      content-class="my-custom-dialog"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Pilih Syarat Promo
          <div class="caption grey--text text--darken-1">
            Atur syarat promo pada form dibawah ini
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: calc(100vh - 118px)" class="py-4 px-0">
          <div class="px-4">
            <div class="subtitle-2">
              Gunakan promo untuk
              <small style="color: #e74c3c"><i>*wajib diisi</i></small>
            </div>
            <v-radio-group
              v-model="formItemType.item_type"
              row
              dense
              hide-details
              class="mt-0 mb-3"
              @change="
              formItemType.page = 1;
              formItemType.totalPage = 1;
              formItemType.data = [];
              formItemType.items = [];
              "
            >
              <v-radio
                label="Kategori"
                value="category"
                color="#4ab762"
              ></v-radio>
              <v-radio
                label="Organisasi"
                value="organization"
                color="#4ab762"
              ></v-radio>
              <v-radio
                label="Publisher"
                value="publisher"
                color="#4ab762"
              ></v-radio>
            </v-radio-group>

            <div class="subtitle-2 mt-4">
              Berlaku pada
              <small style="color: #e74c3c"><i>*wajib diisi</i></small>
            </div>
            <v-radio-group
              v-model="formItemType.item_for"
              row
              dense
              hide-details
              class="mt-0 mb-3"
            >
              <v-radio label="Semua" value="all" color="#4ab762"></v-radio>
              <v-radio
                label="Pilih beberapa"
                value="certain"
                color="#4ab762"
              ></v-radio>
            </v-radio-group>
          </div>

          <div
            style="background: rgba(0, 0, 0, 0.1)"
            class="mb-3 mt-4 px-4 py-2 subtitle-2"
          >
            DATA TERPILIH
          </div>

          <div v-if="formItemType.item_for != 'all'">
            <div class="text-right px-4 mt-4">
              <v-btn
                text
                x-small
                class="px-0 text-none"
                color="#3498db"
                @click="
                dialog.items = true;
                fetchTypePromo();
                "
                ><v-icon left>mdi-plus</v-icon>Tambahkan Data</v-btn
              >
            </div>

            <v-list dense class="pb-0" v-if="formItemType.items.length > 0">
              <div v-for="(item, key) in formItemType.items" :key="key">
                <v-divider></v-divider>
                <v-list-item class="py-0">
                  <v-list-item-avatar
                    class="py-0 rounded-lg"
                    tile
                    color="#ecf0f1"
                  >
                    <span v-if="!item.img_url" style="font-size: 14px">{{
                      item.name | initial
                    }}</span>
                    <v-img v-else :src="item.img_url"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title
                      class="subtitle-2 grey--text text--darken-1"
                      >{{ item.name | emptyData }}</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn small icon color="#e74c3c" @click.stop="
                    formItemType.items.splice(key, 1);
                    ">
                      <v-icon small color="#e74c3c">mdi-delete-forever</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-list>
            <div v-else class="caption text-center mt-4">
              Belum ada data. <br />
              Silahkan klik tombol tambah data diatas.
            </div>
          </div>
          <div v-else class="caption text-left px-4 mt-4">
            Semua
            <span v-if="formItemType.item_type == 'category'">kategori</span>
            <span v-if="formItemType.item_type == 'organization'"
              >organisasi</span
            >
            <span v-if="formItemType.item_type == 'publisher'">publisher</span>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            rounded
            depressed
            class="text-none white--text text--lighten-3 flex-grow-1"
            color="#4ab762"
            @click="insertSyarat(); dialog.itemType = false;"
          >
            <v-icon left>mdi-check-circle-outline</v-icon>Terapkan Syarat Voucher
          </v-btn>
          <v-btn
            rounded
            depressed
            outlined
            class="text-none"
            color="#e74c3c"
            width="130"
            @click="dialog.itemType = false; 
                formItemType.items = form.item_array;
                formItemType.item_type = form.item_type;
                formItemType.item_for = form.item_array.length > 0 ? 'certain' : 'all'"
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.items"
      v-if="dialog.items"
      persistent
      no-click-animation
      max-width="450"
      transition="slide-x-reverse-transition"
      content-class="my-custom-dialog"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Pilih
          <span v-if="formItemType.item_type == 'category'">Kategori</span>
          <span v-if="formItemType.item_type == 'organization'"
            >Organisasi</span
          >
          <span v-if="formItemType.item_type == 'publisher'">Publisher</span>
          <div class="caption grey--text text--darken-1">
            Pilih pada list dibawah ini
          </div>
        </v-card-title>
        <v-divider></v-divider>

        <v-text-field
          dense
          solo
          autocomplete="off"
          color="#4ab762"
          type="text"
          clearable
          clear-icon="mdi-backspace"
          placeholder="Cari berdasarkan nama"
          prepend-inner-icon="mdi-account-search-outline"
          class="rounded-lg mt-4 px-4"
          :disabled="loading"
          v-model="formItemType.search"
        ></v-text-field>

        <div class="text-right px-4 mt-4 caption">
          Data Terpilih
          <b
            >({{
              getChooseData.length == 0
                ? "belum ada"
                : getChooseData.length
            }})</b
          >
        </div>

        <v-list dense class="pb-0">
          <virtual-list
            style="height: calc(100vh - 216px); overflow-y: auto"
            :data-key="'id'"
            :data-sources="formItemType.data"
            :data-component="formItemType.itemTypePromo"
            :extra-props="{ showCheckbox: true }"
          >
            <div slot="footer" class="text-center my-2">
              <v-chip
                color="white"
                text-color="green"
                v-show="formItemType.loading"
              >
                <v-progress-circular
                  indeterminate
                  size="16"
                  width="2"
                  class="mr-4"
                  color="green"
                ></v-progress-circular>
                Memuat data...
              </v-chip>
              <v-btn
                v-show="
                  !formItemType.loading &&
                  formItemType.page != formItemType.totalPage &&
                  formItemType.totalPage != 0
                "
                @click="onScrollToBottom()"
                depressed
                small
                class="text-none white--text"
                color="green"
                >Tampilkan lagi
                <v-icon right small>mdi-arrow-down</v-icon></v-btn
              >
            </div>
            <div
              slot="footer"
              class="caption text-center"
              v-show="formItemType.data.length <= 0 && !formItemType.loading"
            >
              Tidak ada data.
            </div>
          </virtual-list>
        </v-list>

        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            rounded
            depressed
            class="text-none white--text text--lighten-3 flex-grow-1"
            color="#4ab762"
            @click="insertList();"
          >
            <v-icon left>mdi-check-circle-outline</v-icon>Masukkan ke list
          </v-btn>
          <v-btn
            rounded
            depressed
            outlined
            class="text-none"
            color="#e74c3c"
            width="130"
            @click="dialog.items = false"
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.detail"
      v-if="dialog.detail"
      max-width="450"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Detail Promo
          <div class="caption grey--text text--darken-1">
            Informasi Promo Diskon
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          style="height: calc(100vh - 118px)"
          class="py-4 px-4"
          v-if="selected.promo.length > 0"
        >
          <v-list dense class="mt-0">
            <div
              class="
                subtitle-2
                font-weight-bold
                grey--text
                mb-5
                text--darken-1 text-center text-decoration-underline
              "
            >
              Informasi Promo
            </div>
            <div>
              <v-img
                :src="selected.promo[0].image_landscape_url"
                width="100%"
                height="180"
                style="border-radius: 12px;">
              </v-img>
            </div>
            <div class="my-4">
              <v-img
                :src="selected.promo[0].image_portrait_url"
                width="93"
                height="93"
                class="mx-auto"
                style="border-radius: 12px;">
              </v-img>
            </div>
            <v-list-item class="py-0">
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="subtitle-2 text-uppercase grey--text text--darken-1"
                  >Nama Diskon</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="text-right caption grey--text text--darken-1"
                  style="white-space: normal"
                  >{{ selected.promo[0].name | emptyData }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="py-0">
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="subtitle-2 text-uppercase grey--text text--darken-1"
                  >Diskon</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="text-right caption grey--text text--darken-1"
                >
                  <v-chip
                    small
                    class="ma-2 font-weight-bold"
                    label
                    style="
                      color: rgb(52 152 219);
                      background-color: rgb(52 152 219 / 10%);
                    "
                  >
                    <v-icon small left color="rgb(52 152 219)">mdi-sale</v-icon
                    >{{ selected.promo[0].percent + "%" }}
                  </v-chip></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="py-0">
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="subtitle-2 text-uppercase grey--text text--darken-1"
                  >Status Promo</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="text-right caption grey--text text--darken-1"
                >
                  <v-chip
                    small
                    class="ma-2 font-weight-bold"
                    label
                    style="
                      color: rgb(46 204 113);
                      background-color: rgb(46 204 113 / 10%);
                    "
                    v-if="selected.promo[0].active_bool"
                  >
                    Aktif
                  </v-chip>
                  <v-chip
                    small
                    class="ma-2 font-weight-bold"
                    label
                    style="
                      color: rgb(231, 76, 60);
                      background-color: rgb(231 76 60 / 10%);
                    "
                    v-else
                  >
                    Tidak Aktif
                  </v-chip></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="py-0">
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="subtitle-2 text-uppercase grey--text text--darken-1"
                  >TGL MULAI PROMO</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="text-right caption grey--text text--darken-1"
                  >{{
                    selected.promo[0].start_datetime | dateFull
                  }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="py-0">
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="subtitle-2 text-uppercase grey--text text--darken-1"
                  >TGL BERAKHIR PROMO</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="text-right caption grey--text text--darken-1"
                  >{{
                    selected.promo[0].end_datetime | dateFull
                  }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="py-0">
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="subtitle-2 text-uppercase grey--text text--darken-1"
                  >BERLAKU UNTUK</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="text-right caption grey--text text--darken-1"
                >
                  <v-chip
                    small
                    class="ma-2 font-weight-bold"
                    label
                    style="
                      color: rgb(241 196 15);
                      background-color: rgb(241 196 15 / 10%);
                    "
                    v-if="selected.promo[0].item_type == 'category'"
                  >
                    Kategori
                  </v-chip>
                  <v-chip
                    small
                    class="ma-2 font-weight-bold"
                    label
                    style="
                      color: rgb(46 204 113);
                      background-color: rgb(46 204 113 / 10%);
                    "
                    v-if="selected.promo[0].item_type == 'publisher'"
                  >
                    Publisher
                  </v-chip>
                  <v-chip
                    small
                    class="ma-2 font-weight-bold"
                    label
                    style="
                      color: rgb(52 152 219);
                      background-color: rgb(52 152 219 / 10%);
                    "
                    v-if="selected.promo[0].item_type == 'organization'"
                  >
                    Organisasi
                  </v-chip></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="py-0">
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="subtitle-2 text-uppercase grey--text text--darken-1"
                  >PLATFORM</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="text-right caption grey--text text--darken-1"
                >
                  <v-chip
                    small
                    class="ma-2 font-weight-bold"
                    label
                    style="
                      color: rgb(241 196 15);
                      background-color: rgb(241 196 15 / 10%);
                    "
                    v-if="selected.promo[0].platform == 'mobile'"
                  >
                    Aplikasi Mobile
                  </v-chip>
                  <v-chip
                    small
                    class="ma-2 font-weight-bold"
                    label
                    style="
                      color: rgb(52 152 219);
                      background-color: rgb(52 152 219 / 10%);
                    "
                    v-if="selected.promo[0].platform == 'web'"
                  >
                    Aplikasi Web
                  </v-chip>
                  <v-chip
                    small
                    class="ma-2 font-weight-bold"
                    label
                    style="
                      color: rgb(149 165 166);
                      background-color: rgb(149 165 166 / 10%);
                    "
                    v-if="selected.promo[0].platform == 'semua'"
                  >
                    Semua Aplikasi
                  </v-chip></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="py-0">
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="subtitle-2 text-uppercase grey--text text--darken-1"
                  >TGL BUAT</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="text-right caption grey--text text--darken-1"
                  >{{
                    selected.promo[0].input_datetime | datetime
                  }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="py-0">
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="subtitle-2 text-uppercase grey--text text--darken-1"
                  >TERAKHIR DIUBAH</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title
                  class="text-right caption grey--text text--darken-1"
                  >{{
                    selected.promo[0].update_datetime | datetime
                  }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            color="#e74c3c"
            outlined
            depressed
            rounded
            @click="dialog.detail = false"
            class="text-none flex-grow-1"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.confirmDelete"
      persistent
      no-click-animation
      max-width="340"
      content-class="rounded-lg"
    >
      <v-card class="rounded-lg">
        <v-card-title
          class="text-h6 font-weight-bold grey--text text--darken-2"
        >
          <v-icon left>mdi-information</v-icon>Konfirmasi Hapus
        </v-card-title>
        <v-card-text style="min-height: 80px"
          >Apakah yakin akan menghapus promo <b>{{ form.name }}</b
          >?
        </v-card-text>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            color="#4ab762"
            depressed
            rounded
            @click="doDelete"
            class="text-none white--text text--lighten-3 flex-grow-1"
          >
            <v-icon left>mdi-check-circle-outline</v-icon>
            Ya, hapus
          </v-btn>
          <v-btn
            color="#e74c3c"
            outlined
            depressed
            rounded
            @click="dialog.confirmDelete = false"
            class="text-none"
            width="100"
          >
            Batal
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import "moment/locale/id";

import VirtualList from "vue-virtual-scroll-list";

import itemTypePromo from "@/components/ItemTypePromo";

export default {
  name: "Promo",

  components: {
    // ImageUpload: () =>
    //   import(/* webpackChunkName: "ImageUpload" */ "@/components/ImageUpload"),
    "virtual-list": VirtualList,
    ImageUpload: () =>
      import(/* webpackChunkName: "ImageUpload" */ "@/components/ImageUpload"),
    ImageUploadLandscape: () =>
      import(/* webpackChunkName: "ImageUpload" */ "@/components/ImageUploadLandscape"),
  },

  data: () => ({
    breadcrumbs: [
      {
        text: "Dashboard",
        disabled: false,
        href: "/",
      },
      {
        text: "Promo & Voucher",
        disabled: true,
        href: "#",
      },
      {
        text: "Diskon",
        disabled: true,
        href: "/promo-discount",
      },
    ],

    loading: false,
    dialog: {
      promo: false,
      confirmDelete: false,
      detail: false,

      dateStart: false,
      timeStart: false,
      dateEnd: false,
      timeEnd: false,

      applyDateStart: false,
      applyTimeStart: false,
      applyDateEnd: false,
      applyTimeEnd: false,

      itemType: false,
      items: false,
    },
    selected: {
      promo: [],
    },
    headers: {
      promo: [
        {
          text: "NAMA DISKON",
          width: "300",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "DISKON",
          width: "180",
          sortable: true,
          align: "center",
          value: "percent",
        },
        {
          text: "STATUS PROMO",
          width: "180",
          sortable: true,
          align: "center",
          value: "active_bool",
        },
        {
          text: "PERIODE PROMO",
          width: "350",
          sortable: true,
          align: "center",
          value: "periode_array",
        },
        {
          text: "PERIODE PENGAJUAN",
          width: "350",
          sortable: true,
          align: "center",
          value: "apply_periode_array",
        },
        {
          text: "BERLAKU UNTUK",
          width: "250",
          align: "center",
          sortable: true,
          value: "item_type",
        },
        {
          text: "UNTUK PLATFORM",
          width: "180",
          align: "center",
          sortable: true,
          value: "platform",
        },
        {
          text: "TGL BUAT",
          width: "180",
          sortable: true,
          align: "center",
          value: "input_datetime",
        },
        {
          text: "TERAKHIR DIUBAH",
          width: "180",
          sortable: true,
          align: "center",
          value: "update_datetime",
        },
      ],
    },
    dataGrid: {
      promo: [],
    },
    options: {
      promo: {},
    },
    totalData: {
      promo: 0,
    },
    footerProps: {
      "show-current-page": true,
      "show-first-last-page": true,
      "items-per-page-options": [10, 15, 30, 50, 100],
      "items-per-page-text": "Data per halaman",
      "page-text": "{0} - {1} dari total {2}",
    },

    valid: true,
    form: {
      id: "",
      name: "",
      image_square_url: "",
      image_landscape_url: "",
      image_portrait_url: "",
      item_type: "category",
      item_array: [],
      platform: "semua",
      description: "",
      percent: "0",
      start_date: "",
      start_time: "",
      end_date: "",
      end_time: "",
      active_bool: true,
      start_apply_date: "",
      start_apply_time: "",
      end_apply_date: "",
      end_apply_time: "",
    },
    formItemType: {
      search: "",
      searchTimeout: null,
      loading: false,
      page: 1,
      totalPage: 1,
      data: [],

      item_type: "category",
      item_for: "all",
      items: [],

      itemTypePromo,
    },
    validation: {
      id: "",
      name: "",
      item_type: "",
      item_array: "",
      platform: "",
      percent: "",
      start_date: "",
      start_time: "",
      end_date: "",
      end_time: "",
      active_bool: "",
    },

    itemTypeData: [
      {
        value: "category",
        label: "Kategori",
      },
      {
        value: "organization",
        label: "Organisasi",
      },
      {
        value: "publisher",
        label: "Publisher",
      },
    ],
    platformData: [
      {
        value: "semua",
        label: "Semua Platform",
      },
      {
        value: "web",
        label: "Web",
      },
      {
        value: "mobile",
        label: "Mobile",
      },
    ],
  }),

  watch: {
    "options.promo": {
      async handler() {
        this.selected.promo = [];
        await this.fetchData();
      },
      deep: true,
    },

    "formItemType.search": {
      handler() {
        if (this.formItemType.searchTimeout)
          clearTimeout(this.formItemType.searchTimeout);
        this.formItemType.searchTimeout = setTimeout(() => {
          this.formItemType.page = 1;
          this.formItemType.totalPage = 1;
          this.fetchTypePromo();
        }, 800);
      },
    },
  },

  computed: {
    computedStartDate() {
      return this.form.start_date
        ? moment(this.form.start_date).format("DD MMMM YYYY")
        : "";
    },
    computedEndDate() {
      return this.form.end_date
        ? moment(this.form.end_date).format("DD MMMM YYYY")
        : "";
    },
    computedStartApplyDate() {
      return this.form.start_apply_date
        ? moment(this.form.start_apply_date).format("DD MMMM YYYY")
        : "";
    },
    computedEndApplyDate() {
      return this.form.end_apply_date
        ? moment(this.form.end_apply_date).format("DD MMMM YYYY")
        : "";
    },
    computedSyarat() {
      let title = this.form.item_array.length == 0 ? "Semua " : "";
      let subtitle =
        "Dapat digunakan " +
        (this.form.item_array.length == 0
          ? "semua "
          : "(" + this.form.item_array.length + ")");
      if (this.form.item_type == "category") {
        title += "Kategori Kelas";
        subtitle += "kategori kelas";
      }
      if (this.form.item_type == "organization") {
        title += "Organisasi";
        subtitle += "organisasi";
      }
      if (this.form.item_type == "publisher") {
        title += "Publisher";
        subtitle += "publisher";
      }

      title += this.form.item_array.length > 0 ? " tertentu" : "";

      return {
        title,
        subtitle,
      };
    },
    getChooseData() {
      return this.formItemType.data.filter((v) => {
        return v.selected;
      });
    },
  },

  mounted() {},

  methods: {
    formReset() {
      Object.assign(this.form, {
        id: "",
        name: "",
        image_square_url: "",
        image_landscape_url: "",
        image_portrait_url: "",
        item_type: "category",
        item_array: [],
        platform: "semua",
        description: "",
        percent: "0",
        start_date: "",
        start_time: "",
        end_date: "",
        end_time: "",
        active_bool: true,
        start_apply_date: "",
        start_apply_time: "",
        end_apply_date: "",
        end_apply_time: "",
      });
    },
    validationReset() {
      Object.assign(this.validation, {
        id: "",
        name: "",
        image_square_url: "",
        image_landscape_url: "",
        image_portrait_url: "",
        item_type: "",
        item_array: "",
        platform: "",
        percent: "",
        start_date: "",
        start_time: "",
        end_date: "",
        end_time: "",
        active_bool: "",
      });
    },
    setForm() {
      let selected = this.selected.promo[0];
      Object.assign(this.form, {
        id: selected.id,
        name: selected.name,
        item_type: selected.item_type,
        item_array: selected.items,
        platform: selected.platform,
        percent: selected.percent,
        image_square_url: selected.image_square_url,
        image_landscape_url: selected.image_landscape_url,
        image_portrait_url: selected.image_portrait_url,
        description: selected.description,
        start_date: moment(selected.start_datetime).format("YYYY-MM-DD"),
        start_time: moment(selected.start_datetime).format("HH:mm"),
        end_date: moment(selected.end_datetime).format("YYYY-MM-DD"),
        end_time: moment(selected.end_datetime).format("HH:mm"),
        active_bool: selected.active_bool,
        start_apply_date: moment(selected.start_apply_datetime).format("YYYY-MM-DD"),
        start_apply_time: moment(selected.start_apply_datetime).format("HH:mm"),
        end_apply_date: moment(selected.end_apply_datetime).format("YYYY-MM-DD"),
        end_apply_time: moment(selected.end_apply_datetime).format("HH:mm")
      });
    },
    async doSave() {
      let isValid = this.$refs["form-promo"].validate();

      if (isValid) {
        this.form.end_time = `${this.form.end_time}:00`
        this.form.start_time = `${this.form.start_time}:00`
        this.form.start_apply_time = `${this.form.start_apply_time}:00`
        this.form.end_apply_time = `${this.form.end_apply_time}:00`
        this.form.image_portrait_url = this.form.image_square_url
        this.form.item_array = this.form.item_array.map(e => e.id)
        this.$store.state.overlay.show = true;
        this.$store.state.overlay.text = "Menyimpan data promo...";

        let url = "admin/discount";
        if (this.form.id > 0) {
          url = "admin/discount/update";
        }

        let response = await this.$post(url, this.form);

        this.$store.state.overlay.show = false;

        if (response.status == 200) {
          this.$store.state.snackbar = {
            show: true,
            text: "Berhasil menyimpan data promo",
            color: "#2ecc71",
          };
          this.dialog.promo = false;

          this.formReset();
          this.$refs["form-promo"].resetValidation();

          this.validationReset();

          this.selected.promo = [];
          this.fetchData();
        } else if (response.status == 412) {
          this.$store.state.snackbar = {
            show: true,
            text: "Cek kembali form Anda",
            color: "#e74c3c",
          };

          Object.assign(this.validation, response.results.data);
        } else if (response.status == 400) {
          this.$store.state.snackbar = {
            show: true,
            text: response.message,
            color: "#e74c3c",
          };
        }
      }
    },

    async doDelete() {
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Menghapus promo...";

      let response = await this.$post("admin/discount/delete", this.form);

      this.$store.state.overlay.show = false;

      if (response.status == 200) {
        this.$store.state.snackbar = {
          show: true,
          text: "Berhasil menghapus promo",
          color: "#2ecc71",
        };
        this.dialog.confirmDelete = false;

        this.selected.promo = [];
        this.fetchData();
      } else if (response.status == 412) {
        this.$store.state.snackbar = {
          show: true,
          text: "Cek kembali form Anda",
          color: "#e74c3c",
        };
      } else if (response.status == 400) {
        this.$store.state.snackbar = {
          show: true,
          text: response.message,
          color: "#e74c3c",
        };
      }
    },

    async fetchData() {
      this.loading = true;

      let sign =
        this.options.promo.sortDesc && this.options.promo.sortDesc[0]
          ? "-"
          : "";

      let params = {
        page: this.options.promo.page,
        limit: this.options.promo.itemsPerPage,
        sort: "-id",
      };

      if (
        this.options.promo.sortBy.length > 0 &&
        this.options.promo.sortDesc.length > 0
      ) {
        params.sort =
          sign + (this.options.promo.sortBy && this.options.promo.sortBy[0]);
      }

      let response = await this.$get("admin/discount", params);

      this.loading = false;

      if (response.status == 200) {
        this.dataGrid.promo = response.results.data;
        this.totalData.promo = parseInt(response.results.pagination.total_data);
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },

    async fetchTypePromo(){
      this.formItemType.data = []
      let params = {
        page: this.formItemType.page,
        limit: 10,
        'id[nin]':this.formItemType.items.map(e => e.id).join(",")
      };

      if (
        this.formItemType.search &&
        this.formItemType.search.length > 0
      ) {
        params["name[lse]"] = this.formItemType.search;
      }

      this.formItemType.loading = true;

      let url = "";

      switch (this.formItemType.item_type) {
        case 'category':
          url = "admin/course_category";
          break;
        case 'organization':
          url = "organization";
          break;
        case 'publisher':
          url = "admin/user";
          break;
      
        default:
          url = "admin/course_category";
          break;
      }

      let response = await this.$get(url, params);

      this.formItemType.loading = false;

      if (response.status == 200) {
        if (this.formItemType.page <= 1) {
          this.formItemType.data = response.results.data;
        } else {
          this.formItemType.data =
            this.formItemType.data.concat(response.results.data);
        }
        this.formItemType.totalPage = parseInt(
          response.results.pagination.total_page
        );
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },

    async onScrollToBottom() {
      if (this.formItemType.loading) {
        return;
      }

      if (this.formItemType.page + 1 <= this.formItemType.totalPage) {
        this.formItemType.page++;
        this.fetchTypePromo();
      }
    },

    insertList(){
      let selected = this.getChooseData.map((item) => item)

      this.formItemType.items.push(...selected);

      this.dialog.items = false;
    },

    insertSyarat(){
      this.form.item_array = []
      let selected = this.formItemType.items.map((item) => item)
      this.form.item_type = this.formItemType.item_type

      this.form.item_array.push(...selected);
    },
  },
};
</script>

<style>
</style>